<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex'
import { loginRoutine } from '@/store/modules/auth/routines'

export default {
  name: 'LoginSSO',
  methods: {
    ...mapActions({
      login: loginRoutine.TRIGGER
    }),
    failRedirect () {
      this.$router.push({ name: 'login' })
    }
  },
  async beforeMount () {
    // 1. Get correlationId
    const { correlationId } = this.$route.query
    if (!correlationId) {
      this.failRedirect()
      return
    }

    try {
      // 2. Login
      await this.login({ correlationId })
    } catch (e) {
      this.failRedirect()
    }
  }
}
</script>
